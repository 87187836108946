import { Link } from 'gatsby';
import * as React from 'react';

const MovingFirstSection = () => (
  <article className="flex flex-wrap max-w-6xl px-6 py-4 mx-auto mt-20">
    <div className="w-full md:w-1/3">
      <h1 className="text-5xl font-semibold leading-none uppercase heading font-display">
        Interstate <br /> Furniture <br />
        Removals
      </h1>
    </div>
    <div className="w-full mt-6 md:mt-0 md:w-2/3">
      <div className="rich-text">
        <h2>Daunted by your move? We have the moving solution you need</h2>
        <h3>New South Wales furniture removal specialists</h3>
        <p>
          For over {new Date().getFullYear() - 1995} years, we’ve been
          Australia’s trusted furniture removal and relocation company.
          Stationed in Australia’s mid-North Coast, we operate regular runs for
          clients in Sydney, Brisbane, Canberra and along the coast.
        </p>
        <h3>Versatile removalist services for small and large-scale moves</h3>
        <p>
          Whether you’re a business relocating offices, or a family of 5 moving
          to your dream home, our moving services will meet your needs with
          versatility and flexibility.
        </p>
        <p>
          Our team will help you pack up your belongings, hit the road, and
          settle into your new home or office.
        </p>
        <h3>Ensuring a smooth, stress-free experience</h3>
        <p>
          At{' '}
          <Link to="https://www.frontlineremovals.com.au/">
            Frontline Removals
          </Link>
          , we’re dedicated to minimising customer stress by providing reliable,
          fast shipping and excellent customer service. Our moving services are
          tailored to you.
        </p>
        <p>
          Our powerful team of professionals uphold company values that ensure a
          comfortable relocating experience and peace of mind.
        </p>
      </div>
    </div>
  </article>
);

export default MovingFirstSection;
