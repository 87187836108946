import * as React from 'react';

import H3Check from '../utils/h3-check';
import LiCheck from '../utils/li-check';

const MovingSecondSection = () => (
  <article className="flex flex-wrap max-w-6xl px-6 py-4 mx-auto mt-20">
    <div className="w-full mt-6 ml-auto md:mt-0 md:w-2/3">
      <div className="rich-text">
        <h2>What makes our removalist services unique?</h2>
        <H3Check title="Purpose-built shipping containers" />
        <p>
          In addition to the traditional truck and trailer; unlike any other
          moving company in Australia, we pack and ship your belongings in
          purpose-built containers.
        </p>
        <p>
          But why? When we came into business, we recognised a gap in the
          industry where customers were left stranded between moving and storing
          their belongings.
        </p>
        <p>
          With Frontline, you no longer need to seek two separate services for
          relocation and storage — our shipping container solutions bridge the
          gap.
        </p>
        <H3Check title="Cautious, professional handling" />
        <p>
          Tightly securing your furniture and effects, we use purpose-designed
          blankets, padding and plastic covers to protect your belongings.
        </p>
        <p>
          We ensure every furniture removal load is secure before we start the
          journey to your new home or office. With Frontline Removals, your
          effects are safe.
        </p>
        <H3Check title="Tailored, flexible shipping options" />
        <p>
          When it comes to moving, we understand that everyone's timeline is
          different. We work with you to set up a timeline you’re comfortable
          with.
        </p>
        <h4>The way we ship makes your move simple and flexible:</h4>
        <ul>
          <LiCheck>
            Need extra time packing up? Let us know when you’re ready.
          </LiCheck>
          <LiCheck>
            Still setting up your new home? Keep the container as long as you
            need.
          </LiCheck>
        </ul>
        <h2>With Frontline Removals, there are no limitations</h2>
        <p>
          At Frontline, we’re pioneering the relocation industry with
          professional shipping container removalist services.{' '}
          {new Date().getFullYear() - 1995} years in, and we’re just as flexible
          and reliable as we were from the beginning.
        </p>
        <h3>With our expertise, our moving services are limitless:</h3>
        <ul>
          <LiCheck>Packing up a large-scale office? Give us a call!</LiCheck>
          <LiCheck>Moving a 6 bedroom home? No problem!</LiCheck>
        </ul>
      </div>
    </div>
  </article>
);

export default MovingSecondSection;
